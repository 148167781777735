<template>
  <div class="property-wrapper" v-if="control">
    <ControlBaseProperties v-model="control" />
    <ControlSpecificPanel :control="control">
      <label>{{ $t("synoptic.image_list") }}</label>
      <div class="form-group form-group-sm">
        <div class="input-group">
          <div class="input-group-addon">{{ $t("data_source") }}</div>
          <select
            v-model="dataSourceType"
            class="form-control"
            data-testid="source"
          >
            <option
              v-bind:value="item.type"
              v-for="(item, ix) in dataSources"
              v-bind:key="ix"
            >
              {{ $tc(item.label) }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="form-group form-group-sm form-group-no-margin"
        v-if="dataSourceType == 'global'"
      >
        <select
          v-model="dataSourceId"
          class="form-control"
          ref="globalList"
          data-testid="source-id"
        >
          <option
            v-bind:value="item.id"
            v-for="(item, ix) in globalLists"
            v-bind:key="ix"
          >
            {{ $tc(item.name) }}
          </option>
        </select>
      </div>
      <template v-if="stateImages">
        <div
          class="state-image"
          v-for="(item, index) in stateImages"
          :key="index"
          data-testid="state-image"
        >
          <div class="row">
            <div class="form-group form-group-sm form-group-min col-xs-12">
              <label
                :for="`editor-label-${index}`"
                :class="
                  (dataSourceType == 'local' ? 'fa-btn ' : 'fa-btn-disabled ') +
                    (item.state == 'default' ? 'text-primary' : '')
                "
                @click.prevent.stop="setAsDefault(index)"
                data-testid="label"
                :title="$t('hints.set_as_default')"
              >
                <i
                  class="fa"
                  :class="
                    item.state == 'default'
                      ? 'fa-check-circle-o'
                      : 'fa-circle-o'
                  "
                ></i>
                <span style="vertical-align: text-bottom">
                  <span v-if="isEnabled(index)">
                    {{ index + 1 }}.{{ $t("label") }}
                  </span>
                  <span v-else>
                    {{ item.label || $t("label") }}
                  </span>
                </span>
              </label>
              <span
                class="btn btn-xs pull-right"
                v-if="dataSourceType == 'local'"
                @click="removeStateImage(index)"
                :title="$t('synoptic.remove_image')"
                data-testid="remove"
              >
                <i class="glyphicon glyphicon-trash"></i>
              </span>
              <span
                class="btn btn-xs pull-right"
                @click.prevent.stop="toggle(index)"
                :title="$t('expand_collapse_panel')"
                data-testid="collapse"
              >
                <i
                  class="glyphicon glyphicon-collapse-up"
                  v-if="isEnabled(index)"
                ></i>
                <i class="glyphicon glyphicon-collapse-down" v-else></i>
              </span>
              <div class="input-group input-group-sm">
                <input
                  v-if="isEnabled(index)"
                  type="text"
                  class="form-control"
                  v-model="item.label"
                  :id="`editor-label-${index}`"
                  :ref="`editor-label-${index}`"
                  :placeholder="$t('synoptic.insert_label')"
                  :disabled="dataSourceType != 'local'"
                  data-testid="input-label"
                />
                <div
                  class="input-group-addon no-padding"
                  v-if="isEnabled(index)"
                >
                  <ColorPicker
                    v-model="item.backgroundColor"
                    v-bind:pickerStyle="{ left: '-191px' }"
                    icon="background"
                    data-testid="background-color"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isEnabled(index) && item.state != 'default'">
            <div class="form-group form-group-sm form-group-min col-xs-12">
              <label :for="`editor-state-${index}`">{{ $t("value") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="item.state"
                :id="`editor-state-${index}`"
                :placeholder="$t('synoptic.data_value')"
                :disabled="dataSourceType != 'local'"
                data-testid="value"
              />
            </div>
          </div>
          <div class="row" v-if="isEnabled(index)">
            <ImageSelection
              class="col-xs-12"
              :value="(item && item.img) || ''"
              :labelAttrs="{ for: `editor-img-${index}` }"
              :inputAttrs="{ id: `editor-img-${index}` }"
              @input="setImage(item, $event)"
              @imageRemoved="imageRemoved"
              data-testid="image"
            />
          </div>
        </div>
        <div style="margin: 10px 0 50px 0">
          <button
            type="button"
            class="btn btn-xs btn-primary"
            @click="addStateImage"
            v-if="dataSourceType == 'local'"
            data-testid="new-state"
          >
            {{ $t("synoptic.new_image") }}
          </button>
        </div>
      </template>
    </ControlSpecificPanel>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ColorPicker from "@/components/editor/color-picker.vue";
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ImageSelection from "./image-selection";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
export default {
  name: "StatusIcon",
  components: {
    ControlBaseProperties,
    ColorPicker,
    ImageSelection,
    ControlSpecificPanel
  },
  extends: BaseControl,
  data() {
    return {
      control: null,
      enabled: {},
      dataSourceType: "local",
      dataSourceId: "",
      stateImages: [],
      lastData: null
    };
  },
  computed: {
    dataSources() {
      let lst = [{ type: "local", label: "control" }];
      if (this.globalLists?.length) {
        if (this.lastData && this.lastData.text_list) {
          lst.push({ type: "data", label: "data" });
        }
        lst.push({ type: "global", label: "global" });
      }
      return lst;
    },
    equipmentData() {
      if (this.control?.data_id || 0) {
        return (
          (this.$store.getters["dashboard/extendedDataList"] || []).find(
            (i) => i.id == this.control.data_id
          ) || null
        );
      }
      return null;
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    }
  },
  watch: {
    equipmentData(n) {
      if (n) {
        this.lastData = { ...n };
      }
    },
    control: {
      deep: true,
      handler(n, o) {
        if (!n) return;
        this.dataSourceType = n?.synopticComponent?.dataSource?.type || "local";
        this.dataSourceId = n?.synopticComponent?.dataSource?.id || "";
        this.buildItems();
        this.commitChanges(n);
      }
    },
    controls: {
      deep: true,
      handler(n, o) {
        this.control = n.length ? n[0] : null;
      }
    },
    stateImages: {
      deep: true,
      handler(n) {
        this.commitChanges(this.control);
      }
    },
    dataSourceType(n, o) {
      if (n == "global") {
        if (!this.dataSourceId) this.dataSourceId = this.globalLists[0].id;
        this.$nextTick(() => {
          this.$refs.globalList.focus();
        });
      } else if (n == "data") {
        if (!this.dataSourceId)
          this.dataSourceId = this?.lastData?.text_list?.id || o;
      } else {
        if (!this.dataSourceId) this.dataSourceId = "";
      }
      this.buildItems();
    },
    dataSourceId(n) {
      this.buildItems();
    }
  },
  methods: {
    buildItems() {
      let lst = [];
      if (this.dataSourceType == "local") {
        lst = JSON.parse(
          JSON.stringify(this.control?.synopticComponent?.stateImages || [])
        );
      } else {
        let id = this.dataSourceId || 0;
        if (id) {
          let entry = this.globalLists.find((i) => i.id == id) || null;
          for (let key in entry?.items || {}) {
            let value = key in (entry.default_item || {}) ? "default" : key;
            let img = "";
            let stateImages =
              this.control?.synopticComponent?.stateImages || [];
            let len = stateImages?.length || 0;
            if (len) {
              let state =
                stateImages.find(
                  (item) => item.state == key || item.state == value
                ) || null;
              if (state) {
                img = state.img;
              }
            }
            lst.push({
              state: value,
              backgroundColor: "transparent",
              img: img,
              label: entry.items[key]
            });
          }
        }
        this.stateImages = JSON.parse(JSON.stringify(lst));
      }
      this.$set(
        this,
        "stateImages",
        (this.stateImages = JSON.parse(JSON.stringify(lst)))
      );
    },
    setAsDefault(ix) {
      if (!(this.dataSourceType === "local")) return;
      let items = JSON.parse(JSON.stringify(this.stateImages || []));
      let prev = items.find((i) => i.state === "default") || null;
      items[ix].state = "default";
      if (prev) prev.state = "";
      this.$set(this, "stateImages", items);
    },
    setImage(item, img) {
      let stateList = this.stateImages || [];
      if (stateList?.length || 0) {
        let ix = stateList.findIndex((i) => {
          return item.state == i.state;
        });
        if (ix > -1) {
          let items = JSON.parse(JSON.stringify(stateList));
          items[ix].img = img;
          this.$set(this, "stateImages", items);
        }
      }
    },
    imageRemoved(src) {
      // image removed from the database (not from the control)
      let item = (this.stateImages || []).find((i) => src == i.img);
      if (item) {
        this.setImage(item, "");
      }
    },
    commitChanges(control) {
      this.updateControl({
        id: control.id,
        control: {
          ...control,
          synopticComponent: {
            ...control.synopticComponent,
            dataSource: {
              type: this.dataSourceType,
              id: this.dataSourceId
            },
            stateImages: [...this.stateImages]
          }
        },
        noMerge: true
      });
    },
    removeStateImage(index) {
      let stateList = this.stateImages || [];
      stateList.splice(index, 1);
      this.$set(this, "stateImages", stateList);
    },
    addStateImage() {
      let lst = (this.stateImages || []).filter(
        ({ state }) => parseInt(state) == state
      );
      var next =
        (lst || [])
          .map((i) => i.state)
          .reduce(function(a, b) {
            return Math.max(parseInt(a), parseInt(b));
          }, 0) + 1;
      this.stateImages.push({
        backgroundColor: "transparent",
        img: "",
        label: "",
        state: next
      });
      let el = `editor-label-${this.stateImages.length - 1}`;
      this.$nextTick(() => {
        if (this.$refs[el]) this.$refs[el][0].focus();
      });
    },
    toggle(ix) {
      let lst = this.enabled || {};
      if (!(ix in lst)) {
        lst[ix] = true;
      }
      lst[ix] = !lst[ix];
      this.$set(this, "enabled", JSON.parse(JSON.stringify(lst)));
    },
    isEnabled(ix) {
      if (!(ix in this.enabled)) {
        return true;
      }
      return this.enabled[ix];
    },
    ...mapActions("synoptic", ["updateControl"])
  },
  mounted() {
    this.control = this.controls[0];
    this.dataSourceType =
      this.control?.synopticComponent?.dataSource?.type || "local";
    this.dataSourceId = this.control?.synopticComponent?.dataSource?.id || "";
    this.buildItems();
  }
};
</script>

<style scoped>
.state-image {
  margin-top: 1rem;
}

.state-image:not(:last-of-type) {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(195, 195, 195);
}

.form-group-min {
  margin-bottom: 0px;
}

.form-group-no-margin {
  margin-top: -14px;
  text-align: left;
}
.form-group-no-margin > select {
  padding: 0 6px;
}
.fa-btn:hover {
  cursor: pointer;
}
.fa-btn-disabled {
  color: gray;
}
.fa-btn-disabled:hover {
  cursor: not-allowed;
}
</style>
